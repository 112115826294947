import React, { useState, useEffect } from "react";
import MainLayout from "../../components/MainLayout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import {
  assignTicketToAgent,
  changeTicketPriority,
  changeTicketStatus,
  fetchCompanyEmployees,
  fetchTicket,
  fetchTicketMessages,
  sendMessage,
} from "../../config/webclient";
import TicketMessageLog from "../../components/TicketMessageLog";
import { Helmet } from "react-helmet";
import TimeAgo from "../../components/TimeAgo";
import DateTime from "../../components/DateTime";
import Loader from "../../components/Loader";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

export default function ChatLogs() {
  const [message, setMessage] = useState("");
  const [ticketDetails, setTicketDetails] = useState({});
  const [ticketDetilsLoaded, setTicketDetailsLoaded] = useState(false);
  const [ticketLogs, setTicketLogs] = useState([]);
  const [logsLoaded, setLogsLoaded] = useState(false);

  const [employees, setemployees] = useState([]);
  const [employeesLoaded, setEmployeesLoaded] = useState(false);

  const { id } = useParams();
  const ticketId = id;
  var role = localStorage.getItem("role");

  const loadEmployees = async () => {
    var companyId;
    if (role === "parent") {
      companyId = localStorage.getItem("id");
    } else if (role === "child") {
      companyId = localStorage.getItem("parentId");
    } else if (role === "employee") {
      companyId = localStorage.getItem("companyId");
    } else {
      return;
    }
    if (companyId > 0) {
      await fetchCompanyEmployees(companyId)
        .then((response) => {
          console.log(response);
          setemployees(response.data);
          setEmployeesLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changePriority = async (priority) => {
    if (ticketDetilsLoaded) {
      const ticketId = ticketDetails.id;
      await changeTicketPriority({
        ticketId: ticketId,
        priority: priority,
      })
        .then((response) => {
          
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changeStatus = async (status) => {
    if (ticketDetilsLoaded) {
      const ticketId = ticketDetails.id;
      await changeTicketStatus({
        ticketId: ticketId,
        status: status,
      })
        .then((response) => {
          
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changeAgent = async (agentId) => {
    const ticketId = ticketDetails.id;
    console.log(agentId);
    await assignTicketToAgent({
      ticketId: ticketId,
      agentId: agentId,
    })
      .then((response) => {
        console.log(response.log);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onAgentChange = async (event) => {
    event.preventDefault();
    if (setTicketDetailsLoaded) {
      await changeAgent(event.target.value);
      fetchTicketDetails(id);
    }
  };

  const onPriorityChange = async (event) => {
    event.preventDefault();
    if (setTicketDetailsLoaded) {
      await changePriority(event.target.value);
      fetchTicketDetails(id);
    }
  };

  const onStatusChange = async (event) => {
    event.preventDefault();
    if (setTicketDetailsLoaded) {
      await changeStatus(event.target.value);
      fetchTicketDetails(id);
    }
  };

  const sendTicketReply = async (event) => {
    event.preventDefault();

    if (
      localStorage.getItem("role") === "manager" ||
      localStorage.getItem("role") === "user" ||
      localStorage.getItem("role") === "employee" ||
      (localStorage.getItem("role") === "agent" && message.length != 0)
    ) {
      var employeeId = localStorage.getItem("id");
      var ticketId = id;
      try {
        await sendMessage({
          employeeId: employeeId,
          ticketId: ticketId,
          message: message,
        }).then((response) => {
          
          fetchTicketLogs();
          setMessage();
        });
      } catch (error) {
        console.log(error);
      }
    } else if (
      localStorage.getItem("role") === "parent" ||
      localStorage.getItem("role") === "child"
    ) {
      var employeeId = localStorage.getItem("ownerId");
      var ticketId = id;
      try {
        await sendMessage({
          employeeId: employeeId,
          ticketId: ticketId,
          message: message,
        }).then((response) => {
          
          fetchTicketLogs();
          setMessage();
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchTicketLogs = async () => {
    try {
      await fetchTicketMessages(id).then((response) => {
        
        const logs = response.data;
        setTicketLogs(
          logs.sort((b, a) => new Date(b.createdAt) - new Date(a.createdAt))
        );
        setLogsLoaded(true);
      });
    } catch (error) {
      setLogsLoaded(false);
      console.log(error);
    }
  };

  /* Function to autorefresh notifications logs. */
  useEffect(() => {
    const intervelId = setInterval(() => {
      fetchTicketLogs();
    }, 2000);
    return () => clearInterval(intervelId);
  }, []);

  const fetchTicketDetails = async (id) => {
    try {
      await fetchTicket(id).then((response) => {
        
        const logs = response.data;
        setTicketDetails(response.data);
        setTicketDetailsLoaded(true);
      });
    } catch (error) {
      setTicketDetailsLoaded(false);
      setLogsLoaded(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTicketLogs();
    fetchTicketDetails(ticketId);
    loadEmployees();
  }, []);

  const toolbar = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const hasAuthority = () => {
    if (ticketDetilsLoaded) {
      var role = localStorage.getItem("role");
      var id = localStorage.getItem("id");
      switch (role) {
        case "parent":
          return true;

        case "employee":
          return true;

        case "child":
          return false;

        case ticketDetails.project.managerId == id:
          return true;

        default:
          return false;
      }
    }
  };

  return (
    <MainLayout>
      <Helmet>
        <title>{ticketDetails.title}</title>
      </Helmet>
      <div className="container-fluid">
        {ticketDetilsLoaded ? (
          <div className="row">
            <div className="col-md-6 height-75">
              <div className="card p-3">
                <div>
                  {logsLoaded && ticketDetilsLoaded ? (
                    <div>
                      <h5>
                        {ticketDetails.title} #{ticketDetails.id}
                      </h5>
                      <hr />
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: ticketDetails.description,
                        }}
                      />
                      <hr />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {logsLoaded
                  ? ticketLogs.map((message) => (
                      <TicketMessageLog
                        props={{
                          senderName: message.employee.name,
                          timestamp: message.createdAt,
                          message: message.message,
                          timeStamp: message.createdAt,
                        }}
                      />
                    ))
                  : "Loading"}
                {/* {role !== "parent" && role !== "child" ? ( */}

                {ticketDetilsLoaded && ticketDetails.status != "Closed" && (
                  <div className="my-3">
                    <form>
                      <ReactQuill
                        theme="snow"
                        value={message}
                        onChange={setMessage}
                        modules={toolbar}
                        formats={formats}
                      />
                      <button
                        className="btn btn-primary my-3 bg-primary"
                        onClick={(event) => sendTicketReply(event)}
                      >
                        Reply
                      </button>
                    </form>
                  </div>
                )}

                {/* ) : (
                ""
              )} */}
              </div>
            </div>
            <div className="col-md-3 height-75">
              <div className="card p-3">
                {ticketDetails ? (
                  <>
                    <h6 className="mb-0 text-capitalize">
                      {ticketDetails.status ? ticketDetails.status : "Loading"}
                    </h6>
                    <p className="f-13 mb-0">
                      <TimeAgo createdAt={ticketDetails.createdAt} />
                      {" | "}
                      <DateTime createdAt={ticketDetails.createdAt} />
                    </p>
                    {/* <p className="f-13 mb-0 fw-500">Last Updated</p>
                    <p className="f-13 mb-0">
                      {ticketDetails.updatedAt ? (
                        <DateTime createdAt={ticketDetails.updatedAt} />
                      ) : (
                        "Loading"
                      )}
                    </p> */}
                    <div className="my-2">
                      <p className="f-12 mb-0">Group</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.company.name
                          ? ticketDetails.company.name
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Project</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.project.name
                          ? ticketDetails.project.name
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Module</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.projectModule.name
                          ? ticketDetails.projectModule.name
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Priority</p>
                      {role === "parent" || role === "employee" ? (
                        hasAuthority() && (
                          <select
                            className="form-select no-box-shadow f-12"
                            onChange={(event) => onPriorityChange(event)}
                            value={ticketDetails.priority || ""}
                          >
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                            <option>Urgent</option>
                          </select>
                        )
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.priority
                            ? ticketDetails.priority
                            : "Loading"}
                        </p>
                      )}
                    </div>

                    <div className="my-2">
                      <p className="f-12 mb-0">Status</p>
                      {role === "parent" || role === "employee" ? (
                        hasAuthority() && (
                          <select
                            className="form-select no-box-shadow f-12"
                            onChange={(event) => onStatusChange(event)}
                            value={ticketDetails.status || ""}
                          >
                            <option>Open</option>
                            <option>Resolved</option>
                            <option>Reopen</option>
                            <option>In Process</option>
                            <option>Pending</option>
                            <option>Awaiting on Customer</option>
                            <option>Awaiting on Third Party</option>
                            <option>Closed</option>
                          </select>
                        )
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.status
                            ? ticketDetails.status
                            : "Loading"}
                        </p>
                      )}
                    </div>
                    {/* <div className="my-2">
                      <p className="f-12 mb-0">Agent</p>
                      {role === "parent" || role === "employee" ? (
                        hasAuthority() &&
                        employeesLoaded && (
                          <select
                            className="form-select no-box-shadow f-12"
                            onChange={(event) => onAgentChange(event)}
                            value={ticketDetails.agent.id || ""}
                          >
                            {employees.map((employee) => (
                              <option value={employee.id}>
                                {employee.name}
                              </option>
                            ))}
                          </select>
                        )
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.agent
                            ? ticketDetails.agent.name
                            : "UnAssigned"}
                        </p>
                      )}
                    </div> */}
                    <div className="my-2">
                      <p className="f-12 mb-0">Agent</p>
                      {role === "parent" || role === "employee" ? (
                        hasAuthority() &&
                        employeesLoaded && (
                          <select
                            className="form-select no-box-shadow f-12"
                            onChange={(event) => onAgentChange(event)}
                            value={ticketDetails.agent?.id || ""}
                          >
                            <option value="">Unassigned</option>{" "}
                            {/* Default option for Unassigned */}
                            {employees.map((employee) => (
                              <option key={employee.id} value={employee.id}>
                                {employee.name}
                              </option>
                            ))}
                          </select>
                        )
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.agent?.name || "UnAssigned"}{" "}
                          {/* Show UnAssigned if no agent */}
                        </p>
                      )}
                    </div>

                    <div className="my-2">
                      <p className="f-12 mb-0">Estimated Hours</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.estimatedHours
                          ? ticketDetails.estimatedHours
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Approved Hours</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.approvedTime
                          ? ticketDetails.approvedTime
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Actual Support Hours</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.actualSupportHours
                          ? ticketDetails.actualSupportHours
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Business Impact</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.businessImpact !== null
                          ? ticketDetails.businessImpact
                          : "None"}
                      </p>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <div className="col-md-3 height-75">
              <div className="card p-3">
                <h6 className="mb-0 text-capitalize">Timeline</h6>
                <Timeline position="alternate">
                  {ticketDetails && ticketDetails.timeline
                    ? ticketDetails.timeline.map((item, index) => (
                        <TimelineItem key={index}>
                          <TimelineSeparator>
                            <TimelineDot />
                            {/* Only show connector if it's not the last item */}
                            {index < ticketDetails.timeline.length - 1 && (
                              <TimelineConnector />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <span className="f-12">{item.status}</span>
                          </TimelineContent>
                        </TimelineItem>
                      ))
                    : ""}
                </Timeline>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </MainLayout>
  );
}
