import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import { COMPANY_ALL } from "../../config/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";
import { fetchCompanyChildren } from "../../config/webclient";

export default function ViewAllCompany() {
  const [companies, setCompanies] = useState([]);

  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");

  const fetchChildren = async () => {
    await fetchCompanyChildren(id)
      .then((response) => {
        
        setCompanies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching companies:", error);
      });
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(COMPANY_ALL);
        
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    if ((role === "superadmin")) {
      fetchCompanies();
    } else {
      fetchChildren();
    }
  }, []);

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h5>
              {role === "superadmin" ? "All Companies" : "Your Customers"}
            </h5>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>ID</TableCell> */}
                    <TableCell>Name</TableCell>
                    <TableCell>Owner Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies.map((company) =>
                    role !== "superadmin" && company.parentId === id ? (
                      <TableRow key={company.id}>
                        {/* <TableCell>{company.id}</TableCell> */}
                        <TableCell>{company.name}</TableCell>
                        <TableCell>{company.ownerName}</TableCell>
                        <TableCell>{company.email}</TableCell>
                        <TableCell>{company.phoneNumber}</TableCell>
                        <TableCell>{company.address}</TableCell>
                        <TableCell>
                          {new Date(company.createdAt).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={company.id}>
                        {/* <TableCell>{company.id}</TableCell> */}
                        <TableCell>{company.name}</TableCell>
                        <TableCell>{company.ownerName}</TableCell>
                        <TableCell>{company.email}</TableCell>
                        <TableCell>{company.phoneNumber}</TableCell>
                        <TableCell>{company.address}</TableCell>
                        <TableCell>
                          {new Date(company.createdAt).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
