import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  companyGetAll,
  companyGetProjects,
  createTicket,
  fetchAllEmployees,
  fetchCompanyEmployees,
  fetchCompanyTickets,
  fetchParentCompany,
  fetchProjectModules,
  fetchTicketsForAgent,
  fetchTicketsForEmployee,
} from "../config/webclient";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TicketCard from "../components/TicketCard";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function NewTicket() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [companyId, setCompanyId] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);

  const [employeeId, setEmployeeId] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeesLoaded, setEmployeesLoaded] = useState(false);

  const [allEmployees, setAllEmployees] = useState([]);
  const [allEmployeesLoaded, setAllEmployeesLoaded] = useState(false);

  const [raisedById, setRaisedById] = useState(0);

  const [companyProjects, setCompanyProjects] = useState([]);
  const [companyProjectsLoaded, setCompanyProjectsLoaded] = useState(false);
  const [companyProjectId, setCompanyProjectId] = useState(0);

  const [companyProjectModules, setCompanyProjectModules] = useState([]);
  const [projectModulesLoaded, setProjectModulesLoaded] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [contact, setContact] = useState("");
  const [buisnessImpact, setBuisnessImpact] = useState("");
  const [cc, setCc] = useState("");

  const [estimatedHours, setEstimatedHours] = useState("");
  const [actualSupportHours, setActualSupportHours] = useState("");

  const [approvedTime, setApprovedTime] = useState("");

  const [ticketType, setTicketType] = useState("");
  const [ticketstatus, setTicketStatus] = useState("");

  const [ticketPriority, setTicketPriority] = useState("");
  const [projectId, setProjectId] = useState(0);
  const [projectModuleId, setProjectModuleId] = useState(0);
  var role = localStorage.getItem("role");

  /* Fetching companies in the background. */
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        var role = localStorage.getItem("role");
        var companyId;
        if (
          role === "user" ||
          role === "employee" ||
          role === "agent" ||
          role === "manager"
        ) {
          companyId = localStorage.getItem("companyId");
        } else {
          companyId = localStorage.getItem("id");
        }
        await fetchParentCompany(companyId).then((response) => {
          
          setCompanies(response.data);
          setCompaniesLoaded(true);
        });
      } catch (error) {
        setCompaniesLoaded(false);
        console.log(error);
      }
    };
    fetchCompanies();
  }, []);

  /* Fetching all employees in the background. */
  useEffect(() => {
    const fetchEmployeesAll = async (id) => {
      try {
        setEmployeesLoaded(false);
        await fetchAllEmployees(id).then((response) => {
          setAllEmployees(response.data);
          
          setAllEmployeesLoaded(true);
        });
      } catch (error) {
        setAllEmployeesLoaded(false);
        console.log(error);
      }
    };
    fetchEmployeesAll();
  }, []);

  /* Fetching employees for a particular company. */
  const fetchEmployees = async (id) => {
    try {
      setEmployeesLoaded(false);
      await fetchCompanyEmployees(id).then((response) => {
        setEmployees(response.data);
        
        setEmployeesLoaded(true);
      });
    } catch (error) {
      setEmployeesLoaded(false);
      console.log(error);
    }
  };

  /* Fetching projects for a particular company. */
  const fetchCompanyProjects = async (id) => {
    try {
      setEmployeesLoaded(false);
      await companyGetProjects(id).then((response) => {
        setCompanyProjects(response.data);
        
        setCompanyProjectsLoaded(true);
      });
    } catch (error) {
      setCompanyProjectsLoaded(false);
      console.log(error);
    }
  };

  /* Fetching projects for a particular company. */
  const fetchCompanyProjectModules = async (prjectId) => {
    try {
      setEmployeesLoaded(false);
      await fetchProjectModules(projectId).then((response) => {
        setCompanyProjectModules(response.data);
        
        setProjectModulesLoaded(true);
      });
    } catch (error) {
      setProjectModulesLoaded(false);
      console.log(error);
    }
  };

  /* Event handelers. */
  const onCompanyChange = async (event, value) => {
    if (value.id != null) {
      console.log(value.id);
      setCompanyId(value.id);
      await [fetchEmployees(value.id), fetchCompanyProjects(value.id)];
    }
  };

  const onContactChange = async (event, value) => {
    setContact(value.phoneNumber);
  };

  const onEmployeeChange = async (event, value) => {
    setEmployeeId(value.id);
  };

  const onRaisedByChange = async (event, value) => {
    setRaisedById(value.id);
  };

  const onCompanyProjectChange = async (event, value) => {
    if (value != null) {
      setProjectId(value.id);
      console.log(value.projectModules);
      setCompanyProjectModules(value.projectModules);
      setProjectModulesLoaded(true);
    }
  };

  const onProjectModuleChange = async (event, value) => {
    setProjectModuleId(value.id);
  };

  const onApprovedTimeChanged = async (event) => {
    setApprovedTime(event.target.value);
  };

  const type = [
    { label: "Incident" },
    { label: "Query" },
    { label: "Change Request" },
    { label: "Training" },
    { label: "Project" },
    { label: "Service Request" },
  ];
  const status = [
    { label: "Open" },
    { label: "In Process" },
    { label: "Pending" },
    { label: "Awaiting on Customer" },
    { label: "Resolved" },
    { label: "Reopen" },
    { label: "Closed" },
  ];

  const priority = [
    { label: "Low" },
    { label: "Medium" },
    { label: "High" },
    { label: "Urgent" },
  ];

  const toolbar = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const onCreateTIcketSubmit = async (event) => {
    const role = localStorage.getItem("role");
    var raisedByAgentId;
    if (role === "parent" || role === "child") {
      raisedByAgentId = localStorage.getItem("ownerId");
    } else {
      raisedByAgentId = localStorage.getItem("id");
    }
    event.preventDefault();

    console.log({
      projectId: projectId,
      employeeId: raisedByAgentId,
      agentId: role === "user" ? null : employeeId,
      title: title,
      description: description,
      contact: contact,
      type: ticketType,
      buisnessImpact: buisnessImpact,
      applovedTime: role === "user" ? approvedTime : "",
      priority: ticketPriority,
      projectModuleId: projectModuleId,
      cc: cc,
    });
    await createTicket({
      projectId: projectId,
      employeeId: raisedByAgentId,
      agentId: role === "user" ? null : employeeId,
      title: title,
      description: description,
      contact: contact,
      type: ticketType,
      contact: contact,
      estimatedHours: role === "user" ? 1 : estimatedHours,
      actualSupportHours: role === "user" ? 1 : actualSupportHours,
      buisnessImpact: role === "user" ? 1 : buisnessImpact,
      approvedTime: role === "user" ? 1 : approvedTime,
      priority: ticketPriority,
      projectModuleId: projectModuleId,
      cc: cc,
    })
      .then((response) => {
        console.log(response);
        handleSnackbar("Ticket Raised", "success");
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [ticketsLoaded, setTicketsLoaded] = useState(false);
  const [tickets, setTickets] = useState([]);

  const fetchTicketsForRole = async () => {
    try {
      var id = localStorage.getItem("id");
      if (role === "user") {
        await fetchTicketsForEmployee(id).then((response) => {
          setTickets(response.data);
          setTicketsLoaded(true);
        });
      } else if (
        role === "agent" ||
        role === "manager" ||
        role === "employee"
      ) {
        await fetchTicketsForAgent(id).then((response) => {
          // console.log("abcccc", response.data);
          setTickets(response.data);
          setTicketsLoaded(true);
        });
      } else {
        await fetchCompanyTickets(id).then((response) => {
          // console.log("abcccc", response.data);

          setTickets(response.data);
          setTicketsLoaded(true);
        });
      }
    } catch (error) {
      setTicketsLoaded(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTicketsForRole();
  }, []);
  const navigate = useNavigate();
  const navigateToPage = (id) => {
    let role = localStorage.getItem("role");
    if (role === "parent") {
      role = "company";
    } else if (role === "manager") {
      role = "employee";
    }
    navigate(`/${role}/ticket/${id}`);
  };
  return (
    <div className="height-100-hidden">
      <MainLayout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 height-75">
              <div className="card p-3">
                <h5>Create New Ticket</h5>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                  >
                    {snackbarMessage}
                  </MuiAlert>
                </Snackbar>
                <form>
                  {companiesLoaded ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={[companies]}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => onCompanyChange(event, value)}
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Group" />
                      )}
                    />
                  ) : (
                    ""
                  )}
                  {employeesLoaded && role !== "user" ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={employees}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) =>
                        onEmployeeChange(event, value)
                      }
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Agent" />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  <TextField
                    id="outlined-basic"
                    label="Subject"
                    onChange={(event, value) => setTitle(event.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />

                  {employeesLoaded ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={employees}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => onContactChange(event, value)}
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Contact" />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  <br />
                  <TextField
                    id="outlined-basic"
                    label="CC"
                    onChange={(event, value) => setCc(event.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />
                  <label className="m-2">
                    Raised by <br /> <b> {localStorage.getItem("name")}</b>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <Autocomplete
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={type}
                        defaultValue={type[0]}
                        onChange={(event, value) => setTicketType(value.label)}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Type" />
                        )}
                      />
                    </div>
                    <div className="col-md-6">
                      <Autocomplete
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={status}
                        defaultValue={status[0]}
                        onChange={(event, value) =>
                          setTicketStatus(value.label)
                        }
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Status" />
                        )}
                      />
                    </div>
                  </div>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={priority}
                    defaultValue={priority[0]}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => setTicketPriority(value.label)}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Priority" />
                    )}
                  />

                  {companiesLoaded ? (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={companyProjects}
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) =>
                        onCompanyProjectChange(event, value)
                      }
                      renderInput={(params) => (
                        // <TextField {...params} label="Product" />
                        <TextField {...params} label="Project" />
                      )}
                    />
                  ) : (
                    ""
                  )}
                  {projectModulesLoaded ? (
                    <>
                      <Autocomplete
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={companyProjectModules}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) =>
                          onProjectModuleChange(event, value)
                        }
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Modules" />
                        )}
                      />
                      {/* <button className="btn btn-primary bg-primary" onClick={handleOpen}>+</button> */}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Text in a modal
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Duis mollis, est non commodo luctus, nisi erat
                            porttitor ligula.
                          </Typography>
                        </Box>
                      </Modal>
                    </>
                  ) : (
                    "Please select a project"
                  )}
                  <>
                    {role != "user" ? (
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            id="outlined-basic"
                            label="Estimated Hours"
                            onChange={(event) =>
                              setEstimatedHours(event.target.value)
                            }
                            variant="outlined"
                            fullWidth
                            defaultValue="1"
                            sx={{ marginTop: 2, marginBottom: 2 }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            id="outlined-basic"
                            label="Approved  Hours"
                            onChange={(event) =>
                              setApprovedTime(event.target.value)
                            }
                            variant="outlined"
                            fullWidth
                            defaultValue="1"
                            sx={{ marginTop: 2, marginBottom: 2 }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            id="outlined-basic"
                            label="Actual Support Hours"
                            onChange={(event) =>
                              setActualSupportHours(event.target.value)
                            }
                            variant="outlined"
                            fullWidth
                            defaultValue="1"
                            sx={{ marginTop: 2, marginBottom: 2 }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                  <div className="my-2">
                    <label>Description</label>
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                      modules={toolbar}
                      formats={formats}
                    />
                  </div>

                  <TextField
                    id="outlined-basic"
                    onChange={(event) => setBuisnessImpact(event.target.value)}
                    label="Business Impact"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  />

                  <div className="d-flex justify-content-end">
                    <button className="btn mr-1">Cancel</button>
                    <button
                      className="btn btn-primary bg-primary mr-1"
                      onClick={(event) => onCreateTIcketSubmit(event)}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 height-75">
              <div className="card p-3">
                <h5>Tickets</h5>
                <div className="my-2 row col-md-12">
                  {ticketsLoaded ? (
                    tickets
                      .filter(
                        (ticket) =>
                          title === "" // If title is empty, show all tickets
                            ? true
                            : ticket.title
                                .toLowerCase()
                                .includes(title.toLowerCase()) // Filter tickets by matching subject
                      )
                      .map((ticket, index) => (
                        <div className="col-md-12 mb-2" key={ticket.id}>
                          <TicketCard
                            props={{
                              id: ticket.id,
                              title: ticket.title,
                              description: ticket.description,
                              agent: ticket.agent
                                ? ticket.agent.name
                                : "Unassigned",
                              status: ticket.status,
                              employee: ticket.employee.name,
                              createdAt: ticket.createdAt,
                              priority: ticket.priority,
                              project: ticket.projectModule,
                              type: ticket.type,
                              fetchPreview: navigateToPage,
                            }}
                          />
                        </div>
                      ))
                  ) : (
                    <div className="col-md-12">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
            <div className="card p-3"></div>
          </div> */}
          </div>
        </div>
      </MainLayout>
    </div>
  );
}
