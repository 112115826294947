import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MainLayout from "../../components/MainLayout";
import { COMPANY_CREATE, COMPANY_CREATE_CHILD } from "../../config/api";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { enforce10DigitNumbersNoSpaces, enforceNoSpaces, enforceTextAndSpacesOnly } from "../../config/utility";

export default function CreateCompany() {
  var role = localStorage.getItem("role");
  var companyId = localStorage.getItem("id");

  const [formData, setFormData] = useState({
    name: "",
    ownerName: "",
    email: "",
    phoneNumber: "",
    address: "",
    timezone: "",
    password: "",
    parentCompanyId: companyId,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      console.log(formData);
      if (role === "parent") {
        const response = await axios.post(COMPANY_CREATE_CHILD, formData);

        console.log("Company created successfully:", response.data);
        handleSnackbar("Company created successfully", "success");
        window.location.href="/company/company"
      } else {
        const response = await axios.post(COMPANY_CREATE, formData);
        console.log("Company created successfully:", response.data);
        handleSnackbar("Company created successfully", "success");
        window.location.href="/superadmin/company"
      }
      // Optionally, handle success message or redirect
    } catch (error) {
      console.error("Error creating company:", error);
      handleSnackbar(error.response.data.response, "error");
      // Handle error, show error message to user
    }
  };

  return (
    <MainLayout>
      <div className="container my-2">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-5">
              <h5>{role === "parent" ? "Create User" : "Create Company"}</h5>
              <p className="mb-0 f-12">Enter the details below</p>
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">
                  <TextField
                    id="name"
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceTextAndSpacesOnly}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="ownerName"
                    label="Owner Name"
                    variant="outlined"
                    fullWidth
                    value={formData.ownerName}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceTextAndSpacesOnly}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="phoneNumber"
                    label="Mobile"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforce10DigitNumbersNoSpaces}
                  />
                </div>

                <div className="col-md-6">
                  <TextField
                    id="address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={formData.address}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    type="email"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    value={formData.password}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                    onInput={enforceNoSpaces}
                  />
                </div>
                <div className="col-md-6">
                  <button type="submit" className="btn btn-success w-content">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </MainLayout>
  );
}
