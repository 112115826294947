import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddBusinessSharpIcon from "@mui/icons-material/AddBusinessSharp";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PersonAddAltSharpIcon from "@mui/icons-material/PersonAddAltSharp";
import AccountTreeSharpIcon from "@mui/icons-material/AccountTreeSharp";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import { useNavigate } from "react-router-dom";


const drawerWidth = 70;

const closedMixin = (theme) => ({
  overflowX: "hidden",
  width: drawerWidth,
  [theme.breakpoints.up("sm")]: {
    width: drawerWidth,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...closedMixin(theme),
  "& .MuiDrawer-paper": closedMixin(theme),
}));

const menuItems = {
  superadmin: [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      path: "/superadmin/dashboard",
    },
    {
      text: "Create Company",
      icon: <AddBusinessSharpIcon />,
      path: "/superadmin/create-company",
    },
    {
      text: "View Company",
      icon: <ListAltIcon />,
      path: "/superadmin/company",
    },
  ],
  parent: [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/company/dashboard" },
    {
      text: "Create Company",
      icon: <AddBusinessSharpIcon />,
      path: "/company/create-company",
    },
    { text: "View Company", icon: <ListAltIcon />, path: "/company/company" },
    
    {
      text: "Ticket",
      icon: <ConfirmationNumberIcon />,
      path: "/company/tickets",
    },
    { text: "New Ticket", icon: <AddBoxIcon />, path: "/company/new-ticket" },
    {
      text: "Create Agent",
      icon: <PersonAddAltSharpIcon />,
      path: "/company/create-agent",
    },
    {
      text: "View Agents",
      icon: <SupportAgentIcon />,
      path: "/company/agents",
    },
    {
      text: "Create Project",
      icon: <CreateSharpIcon />,
      path: "/company/create-project",
    },
    {
      text: "View Projects",
      icon: <AccountTreeSharpIcon />,
      path: "/company/projects",
    },
  ],
  employee: [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/employee/dashboard" },
    {
      text: "View Tickets",
      icon: <ConfirmationNumberIcon />,
      path: "/employee/tickets",
    },
    { text: "New Ticket", icon: <AddBoxIcon />, path: "/employee/new-ticket" },
  ],
  manager: [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/employee/dashboard" },
    {
      text: "View Tickets",
      icon: <ConfirmationNumberIcon />,
      path: "/employee/tickets",
    },
    { text: "New Ticket", icon: <AddBoxIcon />, path: "/employee/new-ticket" },
  ],
  child: [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/child/dashboard" },
    {
      text: "View Tickets",
      icon: <ConfirmationNumberIcon />,
      path: "/child/tickets",
    },
    { text: "New Ticket", icon: <AddBoxIcon />, path: "/child/new-ticket" },

    {
      text: "Create Agent",
      icon: <PersonAddAltSharpIcon />,
      path: "/child/create-agent",
    },
    { text: "View Agents", icon: <SupportAgentIcon />, path: "/child/agents" },
  ],
  user: [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/user/dashboard" },
    {
      text: "View Tickets",
      icon: <ConfirmationNumberIcon />,
      path: "/user/tickets",
    },
    { text: "New Ticket", icon: <AddBoxIcon />, path: "/user/new-ticket" },
  ],
};


const Sidebar = () => {
  
  const role = localStorage.getItem("role");
  const userMenuItems = menuItems[role] || [];
  const currentPath = window.location.pathname;
  const navigate = useNavigate();
  const pageNavigate = (path) => {
    navigate(path);
  };
  
  return (
    <Drawer variant="permanent">
      <DrawerHeader />
      <Divider />
      <List className="bg-primary">
        {userMenuItems.map((item, index) => {
          const isActive = currentPath === item.path;

          return (
            <Tooltip title={item.text} arrow placement="right" key={item.text}>
              <ListItem
                disablePadding
                sx={{ display: "block" }} 
              >
                <ListItemButton
                  sx={{ minHeight: 48, justifyContent: "center", px: 2.5 }}
                  onClick={() => pageNavigate(item.path)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                    }}
                    className={isActive ? "button-active p-2" : "p-2"}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} sx={{ opacity: 0 }} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
      <Divider />
    </Drawer>
  );
};

export default Sidebar;
