import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useNavigate, Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  fetchCompanyTickets,
  fetchEmployeeNotifications,
  markNotificationViewed,
} from "../config/webclient";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuNavbar from "./MenuNavbar";
import SideProfile from "./SideProfile";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Navbar = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketsLoaded, setTicketsLoaded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // State for notification menu
  const [notifications, setNotifications] = useState([]); // Sample notifications data
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const [notificationLoaded, setNotificationLoaded] = useState(false);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);

    if (value.length > 0) {
      const filtered = tickets.filter(
        (ticket) =>
          ticket.title.toLowerCase().includes(value) ||
          ticket.description.toLowerCase().includes(value) ||
          ticket.agent.name.toLowerCase().includes(value) ||
          ticket.type.toLowerCase().includes(value) ||
          ticket.project.name.toLowerCase().includes(value)
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems([]);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  const profileNavigate = () => {
    const role = localStorage.getItem("role");
    if (role === "parent" || role === "child") {
      navigate(`/company/profile`);
    } else if (role === "employee") {
      navigate(`/employee/profile`);
    } else {
      navigate(`/${role}/profile`);
    }
  };

  /* Function to autorefresh chat logs. */
  useEffect(() => {
    const intervelId = setInterval(() => {
      fetchNotifications();
    }, 3000);
    return () => clearInterval(intervelId);
  }, []);

  const fetchNotifications = async () => {
    var id;
    if (role === "parent" || role === "child") {
      id = localStorage.getItem("ownerId");
    } else {
      id = localStorage.getItem("id");
    }

    await fetchEmployeeNotifications(id)
      .then((response) => {
        
        setNotifications(response.data);
        setNotificationLoaded(true);
      })
      .catch((error) => {
        setNotificationLoaded(false);
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchTickets = async () => {
      var companyId = 0;
      try {
        if (
          role === "user" ||
          role === "employee" ||
          role === "manager" ||
          role === "agent"
        ) {
          companyId = localStorage.getItem("companyId");
        } else {
          companyId = localStorage.getItem("id");
        }
        await fetchCompanyTickets(companyId).then((response) => {
          
          setTickets(response.data);
          setTicketsLoaded(true);
        });
      } catch (error) {
        setTicketsLoaded(false);
        console.log(error);
      }
    };
    fetchTickets();
    fetchNotifications();
  }, []);

  const navigateTicket = (id) => {
    let role = localStorage.getItem("role");
    if (role === "parent" || role === "child") {
      role = "company";
    } else if (role === "manager") {
      role = "employee";
    }

    window.location.href = `/${role}/ticket/${id}`;
    // navigate(`/${role}/ticket/${id}`);
  };

  const navigateNewTicket = () => {
    let role = localStorage.getItem("role");
    if (role === "parent" || role === "child") {
      role = "company";
    } else if (role === "manager") {
      role = "employee";
    }

    navigate(`/${role}/new-ticket`);
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const readNotify = async (id, ticketId) => {
    await markNotificationViewed(id)
      .then((response) => {
        
        fetchNotifications();
      })
      .catch((error) => {
        console.log(error);
      });

    navigateToPage(ticketId);
  };

  const navigateToPage = (id) => {
    let role = localStorage.getItem("role");
    if (role === "parent") {
      role = "company";
    } else if (role === "manager") {
      role = "employee";
    }
    navigate(`/${role}/ticket/${id}`);
  };

  return (
    <div className="bg-white">
      <AppBar position="fixed">
        <Toolbar>
          {/* <Typography variant="h6" noWrap component="div" color="#000">
            {localStorage.getItem("name") || "Logo"}
          </Typography> */}
          <Link to={"/dashboard"}>
            <img className="logo-img" src="/assets/img/logo.png" />
          </Link>
          <MenuNavbar />

          <Box sx={{ flexGrow: 1 }} />
          {role !== "superadmin" ? (
            <>
              <IconButton
                className="new-ticket-btn"
                color="#000"
                onClick={() => navigateNewTicket()}
              >
                <div className="border px-2 py-1 d-flex justify-content-center align-items-center">
                  <AddCircleOutlineIcon sx={{ fontSize: 14 }} />
                  <span className="f-12"> New</span>
                </div>
              </IconButton>
              <div
                className="search-container"
                style={{ position: "relative" }}
              >
                <TextField
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearchChange}
                  sx={{ width: 400 }}
                />
                {filteredItems.length > 0 && (
                  <Paper
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      zIndex: 2,
                      maxHeight: 200,
                      overflow: "auto",
                    }}
                  >
                    <List>
                      {filteredItems.map((item, index) => (
                        <ListItem button key={index}>
                          <div
                            className="d-flex w-100 align-items-center my-1"
                            onClick={() => navigateTicket(item.id)}
                          >
                            <div className="mx-2 search-ticket-icon">
                              <ConfirmationNumberIcon
                                sx={{
                                  backgroundColor: "#E0F5F0",
                                  fontSize: 22,
                                  color: "#33B8B0",
                                  border: "1px solid #33B8B0",
                                  borderRadius: "100px",
                                  padding: "2px",
                                }}
                              />
                            </div>
                            <p className="f-12 mb-0 search-title">
                              {item.title}
                            </p>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                )}
              </div>
              <IconButton color="#000" onClick={handleNotificationClick}>
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleNotificationClose}
              >
                {notifications.length > 0 ? (
                  notifications.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => readNotify(item.id, item.ticketId)}
                    >
                      <div className="d-flex my-2">
                        <div className="notify-card">
                          <p className="mb-0 f-14">{item.id}</p>
                        </div>
                        <div className="mx-2 text-link">
                          <p className="mb-0 f-12 text-grey">
                            {item.notificationTitle}
                          </p>
                          <div>
                            <p className="f-14 mb-0 w-300">
                              {item.notificationMessage}
                            </p>
                            <p className="f-12 text-grey">{item.timestamp}</p>
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Notifications</MenuItem>
                )}
              </Menu>

              <IconButton color="#000">
                <HelpIcon />
              </IconButton>
            </>
          ) : (
            ""
          )}
          <SideProfile />
          {/* <IconButton color="#000">
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <AccountCircleIcon
                    variant="contained"
                    {...bindTrigger(popupState)}
                  />
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => profileNavigate()}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
