import { COMPANY_LOGIN, EMPLOYEE_LOGIN } from "../config/api";
import axios from "axios";

export const loginService = async (email, password, type) => {
  try {
    const endpoint = type === "company" ? COMPANY_LOGIN : EMPLOYEE_LOGIN;
    const response = await axios.post(endpoint, {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Login failed");
  }
};

