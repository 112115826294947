import React from "react";
import MainLayout from "../../components/MainLayout";
import Chart from "react-apexcharts";

const SuperadminDashboard = () => {
  const cardsItem = [
    {
      item: "Unresolved",
      content: "205",
      card: "col-md-6"
    },
    {
      item: "Overdue",
      content: "0",
      card: "col-md-6"
    },
    {
      item: "Due today",
      content: "0",
      card: "col-md-6"
    },
    {
      item: "Open",
      content: "2",
      card: "col-md-6"
    },
    {
      item: "On hold",
      content: "199",
      card: "col-md-12"
    },
    {
      item: "Unassigned",
      content: "1",
      card: "col-md-12"
    },
  ];

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    stroke: {
      width: [2, 2],
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Series 1",
        type: "line",
        data: [30, 40, 25, 50, 49, 21, 70],
      },
      {
        name: "Series 2",
        type: "line",
        data: [23, 12, 54, 61, 32, 56, 81],
      },
    ],
    options: {
      stroke: {
        width: [2, 2],
      },
      colors: ["#008FFB", "#008FFB"],
      xaxis: {
        categories: [
          "0",
          "2",
          "4",
          "6",
          "8",
          "10",
          "12",
          "14",
          "16",
          "18",
          "20",
          "22",
        ],
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Series 1",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Series 2",
            style: {
              color: "#008FFB",
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " points";
            }
            return y;
          },
        },
      },
      legend: {
        labels: {
          useSeriesColors: true,
        },
      },
    },
  };

  return (
    <MainLayout>
      {/* <Typography variant="h4">SuperadminDashboard</Typography> */}
      <div className="row">
        <div className="col-md-5">
          <div className="row">
            {cardsItem.map((item, index) => (
              <div key={index} className={`my-2 ${item.card}`}>
                <div className="card p-3 box-card">
                  <p className="f-14 mb-1">{item.item}</p>
                  <h4
                    className={
                      item.content === "0"
                        ? "text-bolder text-grey"
                        : "text-bolder"
                    }
                  >
                    {item.content}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-7 my-3">
          <div className="card p-3 box-main-card">
            <p>Today's trends</p>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Chart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  type="line"
                  height={250}
                />
              </div>
              <div className="row col-md-12">
                <div className="col-md-3">
                  <p className="f-12 mb-0" title="Resolved">
                    Resolved
                  </p>
                  <h4 className="text-bolder">7</h4>
                </div>
                <div className="col-md-3">
                  <p className="f-12 mb-0" title="Received">
                    Received
                  </p>
                  <h4 className="text-bolder">5</h4>
                </div>
                <div className="col-md-3">
                  <p
                    className="f-12 mb-0 one-line"
                    title="Average First response time in business hours"
                  >
                    Average First response time in business hours
                  </p>
                  <h4 className="text-bolder">3d 15h 2m 13s </h4>
                </div>
                <div className="col-md-3">
                  <p className="f-12 mb-0" title="Resolution">
                    Resolution
                  </p>
                  <h4 className="text-bolder">100%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 my-2">
          <div className="card bottom-card p-3">
            <div className="d-flex my-1 justify-content-between align-items-center">
              <div>
                <p className="mb-0">Undelivered emails</p>
                <p className="mb-0 f-12">Across helpdesk</p>
              </div>
              <div>
                <button className="btn text-theme btn-common p-0">
                  View detials
                </button>
              </div>
            </div>
            <div className="d-flex my-1 justify-content-between align-items-center">
              <div>
                <p className="mb-0 f-12 text-grey">Group</p>
              </div>
              <div>
                <p className="mb-0 f-12 text-grey">Open</p>
              </div>
            </div>
            <div className="d-flex my-1 justify-content-between align-items-center">
              <div>
                <p className="mb-0 text-bolder">InMobi SF Group</p>
              </div>
              <div>
                <p className="mb-0 text-grey">1</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 my-2">
          <div className="card bottom-card p-3">
            <div className="d-flex my-1 justify-content-between align-items-center">
              <div>
                <p className="mb-0 text-bolder">Unresolved tickets</p>
                <p className="mb-0 f-12">Across helpdesk</p>
              </div>
              <div>
                <button className="btn text-theme btn-common p-0">
                  View detials
                </button>
              </div>
            </div>
            <div className="d-flex my-1 justify-content-center align-items-center">
              <div>
                <img
                  className="img-bottom"
                  src="./assets/img/img1.svg"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 my-2">
          <div className="card bottom-card p-3">
            <div className="d-flex my-1 justify-content-between align-items-center">
              <div>
                <p className="mb-0">To do</p>
              </div>
              <div>
                <button className="btn text-theme btn-common p-0">
                  View detials
                </button>
              </div>
            </div>
            <div className="d-flex my-1 justify-content-center align-items-center">
              <div>
                <img
                  className="img-bottom"
                  src="./assets/img/img2.svg"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SuperadminDashboard;
