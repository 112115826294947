// Reports.js
import * as React from "react";
import MainLayout from "../components/MainLayout";
import Typography from "@mui/material/Typography";

const Reports = () => {
  return (
    <MainLayout>
      <Typography variant="h4">Reports</Typography>
    </MainLayout>
  );
};

export default Reports;
