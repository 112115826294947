import React from "react";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AppsIcon from "@mui/icons-material/Apps";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddBusinessSharpIcon from "@mui/icons-material/AddBusinessSharp";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PersonAddAltSharpIcon from "@mui/icons-material/PersonAddAltSharp";
import AccountTreeSharpIcon from "@mui/icons-material/AccountTreeSharp";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import { useNavigate } from "react-router-dom";

const MenuNavbar = () => {
  const menuItems = {
    superadmin: [
      {
        text: "Dashboard",
        icon: <DashboardIcon />,
        path: "/superadmin/dashboard",
      },
      {
        text: "Company",
        icon: <DashboardIcon />,
        submenu: [
          {
            text: "Create Company",
            icon: <AddBusinessSharpIcon />,
            path: "/superadmin/create-company",
          },
          {
            text: "View Company",
            icon: <ListAltIcon />,
            path: "/superadmin/company",
          },
        ],
      },
    ],
    parent: [
      {
        text: "Dashboard",
        icon: <DashboardIcon />,
        path: "/company/dashboard",
      },
      {
        text: "Company",
        icon: <DashboardIcon />,
        submenu: [
          {
            text: "Create Company",
            icon: <AddBusinessSharpIcon />,
            path: "/company/create-company",
          },
          {
            text: "View Company",
            icon: <ListAltIcon />,
            path: "/company/company",
          },
        ],
      },
      {
        text: "Ticket",
        icon: <ConfirmationNumberIcon />,
        submenu: [
          {
            text: "View Tickets",
            icon: <ConfirmationNumberIcon />,
            path: "/company/tickets",
          },
          {
            text: "New Ticket",
            icon: <AddBoxIcon />,
            path: "/company/new-ticket",
          },
        ],
      },
      {
        text: "Agent",
        icon: <PersonAddAltSharpIcon />,
        submenu: [
          {
            text: "Create Agent",
            icon: <PersonAddAltSharpIcon />,
            path: "/company/create-agent",
          },
          {
            text: "View Agents",
            icon: <SupportAgentIcon />,
            path: "/company/agents",
          },
        ],
      },
      {
        text: "Project",
        icon: <AccountTreeSharpIcon />,
        submenu: [
          {
            text: "Create Project",
            icon: <CreateSharpIcon />,
            path: "/company/create-project",
          },
          {
            text: "View Projects",
            icon: <AccountTreeSharpIcon />,
            path: "/company/projects",
          },
        ],
      },
    ],
    employee: [
      {
        text: "Dashboard",
        icon: <DashboardIcon />,
        path: "/employee/dashboard",
      },
      {
        text: "Tickets",
        icon: <ConfirmationNumberIcon />,
        submenu: [
          {
            text: "View Tickets",
            icon: <ConfirmationNumberIcon />,
            path: "/employee/tickets",
          },
          {
            text: "New Ticket",
            icon: <AddBoxIcon />,
            path: "/employee/new-ticket",
          },
        ],
      },
    ],
    manager: [
      {
        text: "Dashboard",
        icon: <DashboardIcon />,
        path: "/manager/dashboard",
      },
      {
        text: "Tickets",
        icon: <ConfirmationNumberIcon />,
        submenu: [
          {
            text: "View Tickets",
            icon: <ConfirmationNumberIcon />,
            path: "/manager/tickets",
          },
          {
            text: "New Ticket",
            icon: <AddBoxIcon />,
            path: "/manager/new-ticket",
          },
        ],
      },
    ],
    child: [
      {
        text: "Dashboard",
        icon: <DashboardIcon />,
        path: "/child/dashboard",
      },
      {
        text: "Tickets",
        icon: <ConfirmationNumberIcon />,
        submenu: [
          {
            text: "View Tickets",
            icon: <ConfirmationNumberIcon />,
            path: "/child/tickets",
          },
          {
            text: "New Ticket",
            icon: <AddBoxIcon />,
            path: "/child/new-ticket",
          },
        ],
      },
      {
        text: "Agent",
        icon: <PersonAddAltSharpIcon />,
        submenu: [
          {
            text: "Create Agent",
            icon: <PersonAddAltSharpIcon />,
            path: "/child/create-agent",
          },
          {
            text: "View Agents",
            icon: <SupportAgentIcon />,
            path: "/child/agents",
          },
        ],
      },
    ],
    user: [
      {
        text: "Dashboard",
        icon: <DashboardIcon />,
        path: "/user/dashboard",
      },
      {
        text: "Tickets",
        icon: <ConfirmationNumberIcon />,
        submenu: [
          {
            text: "View Tickets",
            icon: <ConfirmationNumberIcon />,
            path: "/user/tickets",
          },
          {
            text: "New Ticket",
            icon: <AddBoxIcon />,
            path: "/user/new-ticket",
          },
        ],
      },
    ],
  };

  const role = localStorage.getItem("role");
  const userMenuItems = menuItems[role] || [];
  const currentPath = window.location.pathname;
  const navigate = useNavigate();
  const pageNavigate = (path) => {
    navigate(path);
  };

  return (
    <Dropdown className="dropdown-menu-container">
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        <IconButton>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AppsIcon />
            <Typography sx={{ marginLeft: 1 }}>Menu</Typography>
          </Box>
        </IconButton>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {userMenuItems.map((item, index) => (
          <Dropdown.Item key={index} as="div" className="dropdown-submenu">
            {/* <button
              className={
                item.submenu && item.submenu.length ? "dropdown-toggle" : ""
              }
              type="button"
            >
              {item.text}
            </button> */}

            {/* <button
              className={
                item.submenu && item.submenu.length > 0 ? "dropdown-toggle" : ""
              }
              onClick={() => {
                if (!item.submenu || item.submenu.length === 0) {
                  pageNavigate(item.path);
                }
              }}
              type="button"
            >
              {item.text}
            </button> */}

            <button
              className={
                item.submenu && item.submenu.length > 0
                  ? "custom-dropdown-toggle"
                  : ""
              }
              onClick={() => {
                if (!item.submenu || item.submenu.length === 0) {
                  pageNavigate(item.path);
                }
              }}
              type="button"
            >
              <p className="mb-0 f-14"> {item.text}</p>
              {item.submenu && item.submenu.length > 0 ? (
                <i className="fa fa-chevron-right f-14"></i>
              ) : null}
            </button>

            {item.submenu && item.submenu.length > 0 ? (
              <ul className="dropdown-menu">
                {item.submenu && item.submenu.length > 0
                  ? item.submenu.map((submenuItem, index) => (
                      <li key={index}>
                        <p
                          className="dropdown-item mb-0 f-14"
                          onClick={() => pageNavigate(submenuItem.path)}
                        >
                          {submenuItem.text}
                        </p>
                      </li>
                    ))
                  : null}
              </ul>
            ) : null}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuNavbar;
