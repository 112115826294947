import React from "react";

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const timeZone = localStorage.getItem("timezone") || "UTC";
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    timeZone: timeZone,
  });

  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: timeZone,
  });

  return `${formattedDate} ${formattedTime}`;
};

const DateTime = ({ createdAt }) => {
  return formatDate(createdAt);
};

export default DateTime;
