import React from "react";
import Skeleton from "@mui/material/Skeleton";
export default function Loader() {
  return (
    <>
      <Skeleton
        className="my-2"
        variant="text"
        width={"100%"}
        sx={{ fontSize: "1rem" }}
      />
      <Skeleton className="my-2" variant="circular" width={40} height={40} />
      <Skeleton
        className="my-2"
        variant="rectangular"
        width={"100%"}
        height={60}
      />
      <Skeleton className="my-2" variant="rounded" width={"100%"} height={60} />
    </>
  );
}
