import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { Helmet } from "react-helmet";
import { changeTicketPriority, changeTicketStatus } from "../config/webclient";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import TimeAgo from "./TimeAgo";
import { Snackbar, Alert } from "@mui/material";
import DateTime from "./DateTime";

const TicketCard = ({ props }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [priority, setPriority] = useState(props.priority);
  const [status, setStatus] = useState(props.status);

  const [requestComplete, setRequestComplete] = useState(true);

  const handleStatusChange = async (newStatus) => {
    if (true) {
      await changeTicketStatus({
        ticketId: props.id,
        status: newStatus,
      })
        .then((response) => {
          showSnackbar(`Status changed to ${newStatus}`);
          setStatus(response.data.status);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePriorityChange = async (newPriority) => {
    if (true) {
      setRequestComplete(false);
      await changeTicketPriority({
        ticketId: props.id,
        priority: newPriority,
      })
        .then((response) => {
          setPriority(response.data.priority);
          setRequestComplete(true);
          showSnackbar(`Priority changed to ${newPriority}`);
        })
        .catch((error) => {
          console.log(error);
          setRequestComplete(false);
        });
    }
  };

  const onStatusChange = async (event) => {
    event.preventDefault();
    await handleStatusChange(event.target.value);
  };

  const onPriorityChange = async (event) => {
    console.log(event.target.value);
    // setPriority(event.target.value);
    await handlePriorityChange(event.target.value);
  };

  // const handleChange = (e) => {
  //   setPriority(e.target.value);
  // };

  const getPriorityClass = (priority) => {
    return priority === "Low"
      ? "low-priority"
      : priority === "Medium"
      ? "medium-priority"
      : priority === "High"
      ? "high-priority"
      : "urgent-priority";
  };

  const navigate = useNavigate();

  const navigateToPage = (id) => {
    let role = localStorage.getItem("role");
    if (role === "parent") {
      role = "company";
    } else if (role === "manager") {
      role = "employee";
    }
    navigate(`/${role}/ticket/${id}`);
    // window.location.href = `/${role}/ticket/${id}`;
  };

  const role = localStorage.getItem("role");

  const layout = localStorage.getItem("layout");
  const createdAtDate = new Date(props.createdAt);
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  return (
    <div
      className={
        layout === "grid"
          ? "card box-main-card my-1 padding-lr py-1 height-ticket-grid"
          : "card box-main-card my-1 px-3 py-1"
      }
    >
      {/* <div className="row align-items-center mx-2"> */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>Tickets</title>
      </Helmet>
      <div
        className={
          layout === "grid"
            ? "row mx-2 grid-setup-card"
            : "row mx-2 align-items-center"
        }
      >
        {/* <div className="ticket-card col-md-1"> */}
        <div
          className={
            layout === "grid"
              ? "width-fit-content ticket-card-grid"
              : "ticket-card col-md-1"
          }
        >
          <Tooltip
            title={props.employee}
            arrow
            placement="bottom"
            key={props.id}
          >
            <p className="mb-0 text-center">{props.employee[0]}</p>
          </Tooltip>
        </div>
        <div className="col-md-8">
          <p className="mb-0 latest-ticket px-2 f-11">
            {createdAtDate >= twoDaysAgo && "New" + " "}
            {props.type}
          </p>

          {/* {createdAtDate >= twoDaysAgo && (
            <p className="mb-0 latest-ticket px-2 f-12">New</p>
          )} */}
          <h6
            className={
              layout === "grid"
                ? "mb-0 text-link f-12 text-multi-line fw-normal"
                : "mb-0 text-link one-line fw-normal"
            }
            onClick={
              layout === "grid"
                ? () => navigateToPage(props.id)
                : () => props.fetchPreview(props.id)
            }
          >
            {props.title} #{props.id}
          </h6>
          <p className="f-12 mb-0">
            <TimeAgo createdAt={props.createdAt} />{" "}
            <DateTime createdAt={props.createdAt} />
          </p>
          <p className="f-12 mb-0">
            &#127760; {props.employee} ({props.project.name})
          </p>
          {/* <p className="f-12 mb-0">
            <span dangerouslySetInnerHTML={{ __html: props.description }} />
          </p> */}
        </div>
        {/* <div className="col-md-2 p-0 ticket-card-data"> */}
        <div
          className={
            layout === "grid"
              ? "col-md-4 p-0 ticket-card-data"
              : "col-md-3 p-0 ticket-card-data"
          }
        >
          <div className="d-flex align-items-center my-1 ticket-card-page">
            {/* <p className={`${priority.toLowerCase()}-priority mb-0 p-1 f-12`}>
              &#9632;
            </p> */}
            {/* {priority} */}

            {role !== "user" ? (
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={priority || ""}
                  onChange={(event) => onPriorityChange(event)}
                  sx={{
                    fontSize: "12px",
                    padding: "0 8px",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    boxShadow: "none",
                    "& .MuiSelect-icon": {
                      top: "50%",
                      right: "8px",
                      transform: "translateY(-50%)",
                    },
                    "& .MuiSelect-select": {
                      padding: "4px 0",
                    },
                  }}
                >
                  <MenuItem sx={{ fontSize: "13px" }} value="Low">
                    <span>
                      <span className="low-priority p-1 f-13">&#9632;</span>Low
                    </span>
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "13px" }} value="Medium">
                    <span>
                      <span className="medium-priority p-1 f-13">&#9632;</span>
                      Medium
                    </span>
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "13px" }} value="High">
                    <span>
                      <span className="high-priority p-1 f-13">&#9632;</span>
                      High
                    </span>
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "13px" }} value="Urgent">
                    <span>
                      <span className="urgent-priority p-1 f-13">&#9632;</span>
                      Urgent
                    </span>
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <p className="mb-0 p-1 f-12 text-capitalize">
                {" "}
                <span
                  className={`${props.priority.toLowerCase()}-priority p-1 f-13`}
                >
                  &#9632;
                </span>{" "}
                {props.priority}
              </p>
            )}
          </div>

          {role == "manager" || role === "user" ? (
            <div className="d-flex align-items-center my-1">
              {props.status === "Closed" ? (
                <p className="mb-0 p-1 f-12">Closed</p>
              ) : (
                <p className="mb-0 p-1 f-12 text-capitalize">{props.status}</p>
              )}
            </div>
          ) : (
            <div className="d-flex align-items-center my-1 ticket-card-page">
              {/* <i className="fa fa-calendar f-12 mx-1"></i> */}
              {role !== "manager" || role === "user" ? (
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    onChange={(event) => onStatusChange(event)}
                    value={status}
                    sx={{
                      fontSize: "12px",
                      padding: "0 8px",
                      border: "none",
                      borderRadius: "4px",
                      boxShadow: "none",
                      "& .MuiSelect-icon": {
                        top: "50%",
                        right: "8px",
                        transform: "translateY(-50%)",
                      },
                      "& .MuiSelect-select": {
                        padding: "4px 8px",
                      },
                    }}
                  >
                    <MenuItem sx={{ fontSize: "13px" }} value="open">
                      Open
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value="closed">
                      Closed
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value="resolved">
                      Resolved
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value="reopen">
                      Reopen
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value="in Process">
                      In Process
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value="pending">
                      Pending
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: "13px" }}
                      value="awaiting on customer"
                    >
                      Awaiting on Customer
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: "13px" }}
                      value="awaiting on third party"
                    >
                      Awaiting on Third Party
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value="closed">
                      Closed
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <p className="p-1 mb-0 f-12"> {status}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
