import { React, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import TimeAgo from "./TimeAgo";
import DateTime from "./DateTime";

const TicketMessageLog = ({ props }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };
  return (
    <div className="card box-main-card my-1 p-3">
      <div className="row m-2">
        <div className="chat-card-ticket col-md-1">
          <Tooltip
            title={props.senderName}
            arrow
            placement="bottom"
            key={props.id}
          >
            <p className="mb-0 text-center">{props.senderName[0]}</p>
          </Tooltip>
        </div>
        <div className="col-md-10">
          <p className="mb-0 text-primary">{props.senderName}</p>
          <p className="mb-0 f-12">
            <TimeAgo createdAt={props.timeStamp} />{" "}
            <DateTime createdAt={props.timeStamp} />
          </p>
          <div dangerouslySetInnerHTML={{ __html: props.message }} />
        </div>
      </div>
    </div>
  );
};

export default TicketMessageLog;
