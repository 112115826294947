export const BASE_URL = "http://89.116.134.150:8080/api";

export const COMPANY_CREATE = BASE_URL + "/company";
export const COMPANY_LOGIN = BASE_URL + "/company/login";
export const COMPANY_GET = (companyId) => `${BASE_URL}/company/${companyId}`;
export const COMPANY_GET_ALL = BASE_URL + "/company/all";
export const COMPANY_CREATE_CHILD = BASE_URL + "/company/child";
export const COMPANY_PATCH = (companyId) => `${BASE_URL}/company/${companyId}`;
export const COMPANY_DELETE = (companyId) => `${BASE_URL}/company/${companyId}`;
export const COMPANY_GET_TICKETS = (companyId) =>
  `${BASE_URL}/company/${companyId}/tickets`;
export const COMPANY_GET_CHILD_TICKETS = (companyId) =>
  `${BASE_URL}/company/child/${companyId}/tickets`;
export const COMPANY_GET_PROJECTS = (companyId) =>
  `${BASE_URL}/company/${companyId}/projects`;
export const COMPANY_GET_EMPLOYEES = (companyId) =>
  `${BASE_URL}/company/${companyId}/employee`;
export const COMPANY_GET_CHILDREN = (companyId) =>
  `${BASE_URL}/company/child/${companyId}`;
export const COMPANY_GET_PARENT = (companyId) =>
  `${BASE_URL}/company/${companyId}/parent`;
export const COMPANY_ALL = BASE_URL + "/company/all";

/* Employee endpoints */
export const EMPLOYEE_CREATE = BASE_URL + "/employee";
export const EMPLOYEE_LOGIN = BASE_URL + "/employee/login";
export const EMPLOYEE_GET = (employeeId) =>
  `${BASE_URL}/employee/${employeeId}`;
export const EMPLOYEE_PATCH = (employeeId) =>
  `${BASE_URL}/employee/${employeeId}`;
export const EMPLOYEE_GET_MESSAGES = (employeeId) =>
  `${BASE_URL}/employee/${employeeId}/messages`;
export const EMPLOYEE_ALL = BASE_URL + "/employee/all";
export const EMPLOYEE_DELETE = (employeeId) =>
  `${BASE_URL}/employee/${employeeId}`;

/* Projects endpoints */
export const PROJECT_CREATE = BASE_URL + "/project";
export const PROJECT_GET = (projectId) => `${BASE_URL}/project/${projectId}`;
export const PROJECT_GET_BY_COMPANY_ID = (companyId) =>
  `${BASE_URL}/company/${companyId}/projects`;
export const PROJECT_DELETE = (projectId) => `${BASE_URL}/project/${projectId}`;
export const PROJECT_PATCH = (projectId) => `${BASE_URL}/project/${projectId}`;
export const PROJECT_ADD_MANAGER = BASE_URL + "/project/manager";
export const PROJECT_ADD_EMPLOYEE = BASE_URL + "/project/employee";
export const PROJECT_EMPLOYEES = (projectId) =>
  `${BASE_URL}/project/${projectId}/employees`;
export const PROJECT_ALL = BASE_URL + "/project/all";

/* Project module endpoints. */
export const PROJECT_MODULE_CREATE = BASE_URL + "/project/module";
export const PROJECT_MODULE_GET = (projectModuleId) =>
  `${BASE_URL}/project/module/${projectModuleId}`;
export const PROJECT_MODULE_PATCH = (projectModuleId) =>
  `${BASE_URL}/project/module/${projectModuleId}`;
export const PROJECT_MODULE_DELETE = (projectModuleId) =>
  `${BASE_URL}/project/module/${projectModuleId}`;

/* Ticket endpoints */
export const TICKET_CREATE = BASE_URL + "/ticket";
export const TICKET_GET = (ticketId) => `${BASE_URL}/ticket/${ticketId}`;
export const TICKET_CHANGE_STATUS = BASE_URL + "/ticket/status";
export const TICKET_CHANGE_PRIORITY = BASE_URL + "/ticket/priority";
export const TICKET_ASSIGN_AGENT = BASE_URL + "/ticket/agent";
export const TICKET_GET_ALL = BASE_URL + "/ticket/all";
export const TICKET_PATCH = (ticketId) => `${BASE_URL}/ticket/${ticketId}`;
export const TICKET_DELETE = (ticketId) => `${BASE_URL}/ticket/${ticketId}`;
export const TICKET_CLOSE = (ticketId) =>
  `${BASE_URL}/ticket/close/${ticketId}`;
export const TICKET_GET_MESSAGES = (ticketId) =>
  `${BASE_URL}/ticket/${ticketId}/messages`;
export const TICKET_GET_FOR_EMPLOYEE = (employeeId) =>
  `${BASE_URL}/employee/${employeeId}/tickets`;
export const TICKET_GET_FOR_AGENT = (agentId) =>
  `${BASE_URL}/employee/agent/${agentId}/tickets`;

/* Message endpoints */
export const MESSAGE_CREATE = BASE_URL + "/ticket/message";
export const MESSAGE_GET = (messageId) =>
  `${BASE_URL}/ticket/message/${messageId}`;
export const MESSAGE_DELETE = (messageId) =>
  `${BASE_URL}/ticket/message/${messageId}`;
export const MESSAGE_PATCH = (messageId) =>
  `${BASE_URL}/ticket/message/${messageId}`;

/* Message endpoints */
export const EMPLOYEE_NOTIFICATION = (employeeId) =>
  `${BASE_URL}/employee/${employeeId}/notification`;
export const NOTIFICATION_VIEWED = (notificationId) =>
  `${BASE_URL}/notification/${notificationId}/viewed`;
