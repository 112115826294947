import axios from "axios";
import {
  BASE_URL,
  COMPANY_CREATE,
  COMPANY_CREATE_CHILD,
  COMPANY_DELETE,
  COMPANY_GET,
  COMPANY_GET_ALL,
  COMPANY_GET_CHILD_TICKETS,
  COMPANY_GET_EMPLOYEES,
  COMPANY_GET_PARENT,
  COMPANY_GET_PROJECTS,
  COMPANY_GET_TICKETS,
  COMPANY_LOGIN,
  COMPANY_PATCH,
  EMPLOYEE_ALL,
  EMPLOYEE_CREATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_GET,
  EMPLOYEE_GET_MESSAGES,
  EMPLOYEE_LOGIN,
  EMPLOYEE_PATCH,
  MESSAGE_CREATE,
  PROJECT_ADD_EMPLOYEE,
  PROJECT_ADD_MANAGER,
  PROJECT_CREATE,
  PROJECT_MODULE_GET,
  TICKET_CLOSE,
  TICKET_CREATE,
  TICKET_GET,
  TICKET_GET_ALL,
  TICKET_GET_MESSAGES,
  COMPANY_GET_CHILDREN,
  TICKET_GET_FOR_EMPLOYEE,
  TICKET_GET_FOR_AGENT,
  EMPLOYEE_NOTIFICATION,
  NOTIFICATION_VIEWED,
  TICKET_CHANGE_STATUS,
  TICKET_CHANGE_PRIORITY,
  TICKET_ASSIGN_AGENT
} from "./api";

const webclient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
    "Content-Type": "application/json",
  },
});

/* Create a new company. */
export const createCompany = async (data) => {
  const response = await webclient.post(COMPANY_CREATE, data);
  return response;
};

/* Login for company. */
export const companyLogin = async (data) => {
  const response = await webclient.post(COMPANY_LOGIN, data);
  return response;
};

/* Get company by id. */
export const companyGet = async (companyId) => {
  const response = await webclient.get(COMPANY_GET(companyId));
  return response;
};

/* Create a new company. */
export const createChildCompany = async (data) => {
  const response = await webclient.post(COMPANY_CREATE_CHILD, data);
  return response;
};

/* Patch a given company. */
export const patchCompany = async (companyId, data) => {
  const response = await webclient.patch(COMPANY_PATCH(companyId), data);
  return response;
};

export const deleteCompany = async (companyId) => {
  const response = await webclient.get(COMPANY_DELETE(companyId));
  return response;
};

/* Get company by id. */
export const companyGetTickets = async (companyId) => {
  const response = await webclient.get(COMPANY_GET_TICKETS(companyId));
  return response;
};

/* Get company by id. */
export const companyGetProjects = async (companyId) => {
  const response = await webclient.get(COMPANY_GET_PROJECTS(companyId));
  return response;
};

/* Get company by id. */
export const companyGetEmployees = async (companyId) => {
  const response = await webclient.get(COMPANY_GET_EMPLOYEES(companyId));
  return response;
};
/* Get all companies. */
export const companyGetAll = async () => {
  const response = await webclient.get(COMPANY_GET_ALL);
  return response;
};

/* Employee Requests */

/* Create a new employee. */
export const createEmployee = async (data) => {
  const response = await webclient.post(EMPLOYEE_CREATE, data);
  return response;
};

/* Login for employee. */
export const employeeLogin = async (data) => {
  const response = await webclient.post(EMPLOYEE_LOGIN, data);
  return response;
};

/* Get employee by id. */
export const employeeGet = async (employeeId) => {
  const response = await webclient.get(EMPLOYEE_GET(employeeId));
  return response;
};

/* Get employee by id. */
export const employeePatch = async (employeeId, data) => {
  const response = await webclient.patch(EMPLOYEE_PATCH, data);
  return response;
};

/* Get messages by employee by id. */
export const employeeGetMessages = async (employeeId) => {
  const response = await webclient.get(EMPLOYEE_GET_MESSAGES(employeeId));
  return response;
};

export const employeeAll = async () => {
  const response = await webclient.EMPLOYEE_ALL();
  return response;
};

export const employeeDelete = async (employeeId) => {
  const response = await webclient.delete(EMPLOYEE_DELETE(employeeId));
  return response;
};

export const createProject = async (data) => {
  const response = await webclient.post(PROJECT_CREATE, data);
  return response;
};

export const assignProjectManager = async (data) => {
  const response = await webclient.post(PROJECT_ADD_MANAGER, data);
  return response;
};

export const assignProjectEmployees = async (data) => {
  const response = await webclient.post(PROJECT_ADD_EMPLOYEE, data);
  return response;
};

export const fetchCompanyTickets = async (companyId) => {
  var response = await webclient.get(COMPANY_GET_TICKETS(companyId));
  return response;
};

export const fetchTicketMessages = async (ticketId) => {
  var response = await webclient.get(TICKET_GET_MESSAGES(ticketId));
  return response;
};

export const fetchParentCompany = async (companyId) => {
  // console.log(companyId);
  var response = await webclient.get(COMPANY_GET_PARENT(companyId));
  return response;
};

export const fetchCompanyEmployees = async (companyId) => {
  var response = await webclient.get(COMPANY_GET_EMPLOYEES(companyId));
  console.log(response);
  return response;
};

export const fetchAllEmployees = async (companyId) => {
  var response = await webclient.get(EMPLOYEE_ALL);
  return response;
};

export const fetchProjectModules = async (projectId) => {
  var response = await webclient.get(PROJECT_MODULE_GET(projectId));
  return response;
};

export const createTicket = async (data) => {
  var response = await webclient.post(TICKET_CREATE, data);
  return response;
};

export const sendMessage = async (data) => {
  var response = await webclient.post(MESSAGE_CREATE, data);
  return response;
};

export const addEmployeesToProject = async (data) => {
  var response = await webclient.post(PROJECT_ADD_EMPLOYEE, data);
  return response;
};

export const fetchTicket = async (id) => {
  var response = await webclient.get(TICKET_GET(id));
  return response;
};

export const fetchAllTickets = async () => {
  var response = await webclient.get(TICKET_GET_ALL);
  return response;
};

export const closeTicket = async (ticketId) => {
  var response = await webclient.get(TICKET_CLOSE(ticketId));
  return response;
};

export const fetchCompanyChildren = async (parentId) => {
  var response = await webclient.get(COMPANY_GET_CHILDREN(parentId));
  return response
};
export const fetchChildCompanyTickets = async (companyId) => {
  var response = await webclient.get(COMPANY_GET_CHILD_TICKETS(companyId));
  return response;
};

export const fetchTicketsForEmployee = async (employeeId) => {
  var response = await webclient.get(TICKET_GET_FOR_EMPLOYEE(employeeId));
  return response;
}

export const fetchTicketsForAgent = async (agentId) => {
  var response = await webclient.get(TICKET_GET_FOR_AGENT(agentId));
  return response;
}


export const fetchEmployeeNotifications = async (employeeId) => {
  var response = await webclient.get(EMPLOYEE_NOTIFICATION(employeeId));
  return response;
}

export const markNotificationViewed = async (notificationId) => {
  var response = await webclient.get(NOTIFICATION_VIEWED(notificationId));
  return response;
}

export const changeTicketStatus = async (data) => {
  var response = await webclient.post(TICKET_CHANGE_STATUS, data);
  return response;
}

export const changeTicketPriority = async (data) => {
  var response = await webclient.post(TICKET_CHANGE_PRIORITY, data);
  return response;
}

export const assignTicketToAgent = async (data) => {
  var response = await webclient.post(TICKET_ASSIGN_AGENT, data);
  return response;
}